import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
  
import { palette } from '@mirinae/react-ui';
import { UserAgent, ExternalLinks } from '@mirinae/js-utils';

import GlobalContext from '@mirinae/explorer/modules/defines/contexts';
import { explorerPaths, authPaths, membersPaths } from '@mirinae/shared/defines/paths';
import BgStars from '../assets/bg-button-pattern-stars.png';
import Localization from '@mirinae/shared/il8n';
import { currentPricePlanVersion, currentTrialDays } from '@mirinae/shared/defines/datas';
import { useAppVersionStore, useAppVersionStoreMethods } from '@mirinae/stores/appVersion';

const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 40px;
    height: 56px;
    min-width: fit-content;
    color: ${palette.primary.white};
    font-size: 14px;
    font-weight: 700;
    background: url(${BgStars}) no-repeat 0 0/cover, linear-gradient(90deg, #3499FF -70.26%, #3A3985 121.84%);
    box-shadow: 0 4px 18px rgba(40, 96, 162, 0.37);
    border-radius: 10px;
    white-space: nowrap;
    cursor: pointer;
  
    :hover {
        background: url(${BgStars}) no-repeat 0 0/cover, linear-gradient(90deg, #275393 -70.26%, #293168 121.84%);
    }
  
    :disabled {
        background-color: ${palette.gray[6]};
    }
  
    span {
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
    }
`;

const TryFreeLink = ({ trialStatus, style, onClick }) => {
    const { isApp, isPricePlan3PlusEnabled } = useAppVersionStore();
    const { setVersionData } = useAppVersionStoreMethods();

    return  (
        <>
            { !isApp ? (
                trialStatus === 'no-trial' ? (
                    <Button reloadDocument href={`${authPaths.signup}?redirect=${membersPaths.payment}`} style={style}>
                        {Localization['try-it-free-7'].replace('{trialDays}', currentTrialDays)}
                    </Button>
                ) : (
                    <Button reloadDocument href={`${membersPaths.payment}`} onClick={onClick} style={style}>
                        {Localization['plan-StP']}
                    </Button>
                )
            ) : (
                <Button style={style} onClick={(e) => {
                    e.preventDefault();
                    if (isApp && !isPricePlan3PlusEnabled) {
                        setVersionData({ displayMustUpgradeApp: 'pp3-explain-subscribe' });
                    } else  if (onClick) {
                        onClick();
                    } else if (window.PaymentChannel) {
                        window.PaymentChannel.postMessage(true);
                    } else if (window.flutter_inappwebview) {
                        window.flutter_inappwebview.callHandler('PaymentChannel', true);
                    }
                }}>
                    { trialStatus === 'no-trial' ? Localization['try-it-free-7'].replace('{trialDays}', currentTrialDays) : <>{Localization['plan-StP']}</> }
                </Button>
            )}
        </>
    );
};

export default TryFreeLink;