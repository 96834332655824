import React from 'react';
import styled from 'styled-components';

import { palette, zIndex, breakPoint } from '@mirinae/react-ui';

import { ReactComponent as IconAlert } from '@mirinae/explorer/components/assets/alert.svg';
import { ReactComponent as IconClose } from '@mirinae/explorer/components/assets/icon-close-fixedtopbanner.svg';

const PopupWrapper = styled.div`
    position: fixed;
    inset: 0;
    z-index: ${zIndex.feedbackPopup};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.2);
    overflow: auto;
`;

const PopupInner = styled.div`
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 350px;
    padding: 40px;
    background-color: ${palette.primary.white};
    box-shadow: 0px 0px 15px rgba(196, 196, 196, 0.48);
    border-radius: 10px;
    button {
        width: 100%;
    }
    @media only screen and (max-width: ${breakPoint.common.md}) {
        padding: 30px;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    h5 {
        line-height: 28px;
        color: ${palette.gray[1]};
        font-size: 18px;
        font-weight: 700;
    }
    p {
        color: #555;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const Resend = styled.div`
    cursor: pointer;
    text-decoration: underline;
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
`;

const VerifyEmailReminder = ({ email, close }) => {

    const resend = async () => {
        await fetch('/api/auth/signup/send-verification', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });
        close();
    };

    return (
        <PopupWrapper>
            <PopupInner>
                <IconAlert />
                <Text>
                    <h5>Please verify email!</h5>
                    <p>It looks like you have not verified your email address.</p>
                    <p>We sent you a verfication email when you signed-up.
                    You need to verify your address in order to access your account.
                    </p>
                    <Resend onClick={resend}>Resend</Resend>
                </Text>
                <CloseButton onClick={close}><IconClose /></CloseButton>
            </PopupInner>
        </PopupWrapper>
    );
};

export default VerifyEmailReminder;